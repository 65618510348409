import React from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles'
import { isEmpty, useFirebase } from 'react-redux-firebase'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ForgotPasswordForm from './ForgotPasswordForm'
import { availableLanguages } from '../../config'
import MenuItem from '@material-ui/core/MenuItem'
import GlobeIcon from '@material-ui/icons/Language'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import useI18n from '../../misc/languages/useI18n'
import Typography from '@material-ui/core/Typography'
import packageJson from '../../../package.json'

const Background = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100dvh',
  background: `url(${require('../../assets/images/background.jpg')})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflow: 'auto',
  [`@media (max-height: 650px)`]: {
    alignItems: 'flex-start',
  },
}))

const SignInPanel = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 360,
  backgroundColor: 'rgba(255, 255, 255, 0.85)',
}))

const SignIn = () => {
  const firebase = useFirebase()
  const auth = useSelector((state) => state.firebase.auth)
  const profile = useSelector((state) => state.firebase.profile)
  const history = useHistory()
  const { t, locale } = useI18n()
  React.useEffect(() => {
    // TODO: its ok not to verify email for now to speed up user registrations
    // if (!isEmpty(auth) && auth.emailVerified) {
    if (!isEmpty(auth) && profile.isLoaded && profile.role) {
      window.localStorage.removeItem('logging_in')
      history.replace('/app')
    } else if (!isEmpty(auth) && profile.isLoaded && !profile.role) {
      setStage('signup')
      setIsOnboarding(true)
    } else if (isEmpty(auth)) {
      window.localStorage.removeItem('logging_in')
    }
  }, [auth, profile])
  const [stage, setStage] = React.useState('signin')
  const [isOnboarding, setIsOnboarding] = React.useState(false)

  return (
    <Background maxWidth="xs">
      <SignInPanel elevation={3}>
        <Box width="40%">
          <img style={{ width: '100%' }} src={require('../../assets/images/logo.png')} />
        </Box>
        {stage === 'signin' ? (
          <SignInForm
            onSignUp={() => setStage('signup')}
            onForgotPassword={() => setStage('forgotPassword')}
          />
        ) : null}
        {stage === 'signup' ? (
          <SignUpForm
            onBack={() => {
              setStage('signin')
              firebase.logout()
            }}
            isOnboarding={isOnboarding}
            auth={auth}
          />
        ) : null}
        {stage === 'forgotPassword' ? (
          <ForgotPasswordForm onBack={() => setStage('signin')} />
        ) : null}
        <br />
        <TextField
          select
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GlobeIcon />
              </InputAdornment>
            ),
          }}
          defaultValue={locale}
          onChange={(e) => {
            localStorage.setItem('language', e.target.value)
            window.location.reload(false)
          }}
        >
          {availableLanguages.map((l) => (
            <MenuItem key={l.value} value={l.value}>
              {l.label}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <Typography
          variant="caption"
          color="textSecondary"
          align="center"
          dangerouslySetInnerHTML={{ __html: t('agree to tc pp') }}
        />
        <br />
        <Typography variant="caption">v{packageJson.version}</Typography>
      </SignInPanel>
    </Background>
  )
}

export default SignIn
